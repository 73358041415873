import { Box, Button, Grid, Typography, Stack } from '@arquivei/atenas';

const ExploreApiSection = () => {
  return (
    <Box component="section" data-testid="explore-api-section">
      <Grid container sx={{ py: { xs: 6, md: 12 } }}>
        <Stack direction="column" alignItems="center" spacing={4}>
          <Grid xs={12} md={12} textAlign="center">
            <Typography level="display2" gutterBottom>
              Explore nossa API e tire o melhor de suas notas
            </Typography>
          </Grid>
          <Grid xs={12} md={10} display="flex" justifyContent="center">
            <Typography
              level="h2"
              fontWeight="sm"
              textAlign="center"
              textColor="text.secondary"
              width="80%"
              gutterBottom
            >
              Comece agora a testar nossas APIs e integrações de forma rápida e
              segura via Sandbox e deixe de lado toda burocracia.
            </Typography>
          </Grid>
          <Grid md={2}>
            <Button
              component="a"
              href="/sandbox"
              sx={{
                borderRadius: '4px',
                height: '56px',
                width: '206px',
                fontSize: '16px',
                mt: 2,
                backgroundColor: '#FF4719',
                '&:hover:not(.Joy-focused)': {
                  backgroundColor: '#D13200',
                },
              }}
            >
              Testar APIs agora
            </Button>
          </Grid>
        </Stack>
      </Grid>
    </Box>
  );
};

export default ExploreApiSection;
