import { Card, Box, Grid, Typography, Stack } from '@arquivei/atenas';
import Image from 'next/image';

import boticarioLogo from '../../assets/boticario-logo.svg';
import ceaLogo from '../../assets/cea-logo.svg';
import filesTime from '../../assets/files-time.svg';
import ifoodLogo from '../../assets/ifood-logo.svg';
import longpingLogo from '../../assets/longping-logo.svg';
import mondelezLogo from '../../assets/mondelez-logo.svg';
import notebookSync from '../../assets/notebook-sync-cloud.svg';
import nubankLogo from '../../assets/nubank-logo.svg';
import viaLogo from '../../assets/via-logo.svg';
import xmlFiles from '../../assets/xml-files.svg';

const companiesUsingOurApis = [
  {
    img: ceaLogo,
    name: 'C&A',
    width: 62,
    height: 47,
  },
  {
    img: boticarioLogo,
    name: 'Boticário',
    width: 179,
    height: 33,
  },

  {
    img: ifoodLogo,
    name: 'iFood',
    width: 87,
    height: 45,
  },
  {
    img: viaLogo,
    name: 'Via',
    width: 74,
    height: 54,
  },
  {
    img: mondelezLogo,
    name: 'Mondelez',
    width: 137,
    height: 33,
  },
  {
    img: nubankLogo,
    name: 'Nubank',
    width: 61,
    height: 33,
  },
  {
    img: longpingLogo,
    name: 'LongPing',
    width: 129,
    height: 33,
  },
];

const ourApiUseData = [
  {
    name: 'xmlDownload',
    title: '+2 Milhões',
    img: filesTime,
    description: 'De consultas diárias são feitas diariamente via API Qive.',
  },
  {
    name: 'pdfs',
    title: '+560 Mil ',
    img: notebookSync,
    description:
      'Downloads de NFe, CTe e NFSe são realizados diariamente via API integradas.',
  },
  {
    name: 'api',
    title: '+80% ',
    img: xmlFiles,
    description:
      'De produtividade nas automatizações que usam as integrações Qive.',
  },
];

const OurApiUse = () => {
  return (
    <Box component="section" data-testid="resources-section">
      <Grid container>
        <Grid
          justifyContent="center"
          md={12}
          xs={12}
          sx={{ pt: { xs: 3, md: 12 }, width: 1 }}
        >
          <Typography level="display2" gutterBottom textAlign="center">
            O que o uso de nossas APIs representa
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          justifyContent: {
            md: 'space-around',
            xs: 'center',
          },
        }}
      >
        {ourApiUseData.map((item) => {
          return (
            <Grid key={item.name} mt={8} data-testid="oi">
              <Card
                data-testid="resources-card"
                sx={{
                  height: '324px',
                  justifyContent: 'center',
                  maxWidth: { xs: '100%', sm: '348px' },
                  px: 3,
                  py: 5,
                }}
                variant="outlined"
              >
                <Grid
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  gap={3}
                >
                  <Image
                    height={item.img.height}
                    src={item.img.src}
                    width={item.img.width}
                  />

                  <Typography level="h2">{item.title}</Typography>
                  <Typography
                    level="body1"
                    textAlign="center"
                    textColor="text.secondary"
                  >
                    {item.description}
                  </Typography>
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Stack direction="row" justifyContent="center" mt={12}>
        <Typography level="display2" gutterBottom>
          Mais de 10.000 empresas já utilizam nossa API
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-around"
        mt={8}
        alignItems="center"
      >
        {companiesUsingOurApis.map((company) => {
          return (
            <Box key={company.name}>
              <Image
                src={company.img}
                alt={`logo da empresa ${company.name}`}
                width={company.width}
                height={company.height}
              />
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default OurApiUse;
