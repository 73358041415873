import { Box, Container } from '@arquivei/atenas';
import { CssBaseline } from '@mui/material';
import Head from 'next/head';

import communityBg from '../assets/community-cover.png';
import needhelpBg from '../assets/need-help.png';
import HeaderDarken from '../components/HeaderDarken/HeaderDarken';
import ApiDocumentationSection from '../components/Home/ApiDocumentationSection';
import Community from '../components/Home/Community';
import ExploreApiSection from '../components/Home/ExploreApiSection';
import MainResources from '../components/Home/MainResources';
import NeedHelp from '../components/Home/NeedHelp';
import OurApiUse from '../components/Home/OurApiUse';
import Layout from '../components/Layout';

const IndexPage = () => {
  return (
    <Layout>
      <Head>
        <title>Home</title>
      </Head>
      <CssBaseline />

      <Box bgcolor="black" gap={10}>
        <HeaderDarken />
        <Container sx={{ py: '2rem' }}>
          <ApiDocumentationSection />
        </Container>
      </Box>

      <Box sx={{ mb: 19 }}>
        <Container maxWidth="xl">
          <MainResources />
        </Container>
      </Box>

      <Box
        sx={{
          backgroundImage: `url(${communityBg.src})`,
          minHeight: '70vh',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container maxWidth="xl">
          <Community />
        </Container>
      </Box>

      <Box sx={{ mb: 8 }}>
        <Container maxWidth="xl">
          <OurApiUse />
        </Container>
      </Box>

      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.neutral[50],
        })}
      >
        <Container>
          <ExploreApiSection />
        </Container>
      </Box>

      <Box
        sx={{
          backgroundImage: `url(${needhelpBg.src})`,
          minHeight: '70vh',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container maxWidth="xl">
          <NeedHelp />
        </Container>
      </Box>
    </Layout>
  );
};

export default IndexPage;
